import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { getAuthenticated } from '@/store/app';
import { useAppSelector } from '@/store/hooks';

export const HomePage = () => {
  const location = useLocation();
  
  const isDomainOrIP = (host:string) => {
    // Regular expression to match IPv4
    const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    
    // Regular expression to match IPv6
    const ipv6Pattern = /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;
  
    // Regular expression to match domain name
    const domainPattern = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
  
    if (ipv4Pattern.test(host)) {
      return 'IPv4';
    } else if (ipv6Pattern.test(host)) {
      return 'IPv6';
    } else if (domainPattern.test(host)) {
      return 'Domain';
    } else {
      return 'Invalid';
    }
  };
  const auth = useAppSelector(getAuthenticated());
  const isLocalhost = isDomainOrIP(window.location.hostname) === 'IPv4';
  console.log(auth);
  if(!isLocalhost) {
    if (!auth) {
      return ( <Navigate to="/login" state={{ from: location }} replace />)
    }
    else
    {
      return (<Navigate to="/project" state={{ from: location }} replace />)
    }
  }
  else
  {
    return (<Navigate to="/Project/WeightList" state={{ from: location }} replace />)
  }
  
};
