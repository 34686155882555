import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import styles from './AuthLayout.module.less';
import { LayoutHeader } from './Header/LayoutHeader';
import { LeftSider } from './Sider/LeftSider';
import { RemoveTrailingSlash } from '../RemoveTrailingSlash';

const { Content } = Layout;

const MainLayout = () => {

  return (
    <>
      <RemoveTrailingSlash />
          <Layout className={styles.mainContainer}>
            <LayoutHeader />
            <Layout>
              <LeftSider />
              <Layout>
                <Content className={styles.contentContainer}>
                  <Outlet />
                </Content>
              </Layout>
            </Layout>
          </Layout>
    </>
  );
};

export default MainLayout;
