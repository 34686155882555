import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { PagingResponse } from '@/common/define';
import { CreateProjectData } from '@/common/project';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface ProjectStatusListPagingResponse extends PagingResponse {
  results: ProjectStatusResponse[];
}

export interface ProjectStatusResponse {
  id: number;
  name: string;
  code: string;
  description: string;
  status: number;
  order: number;
  type: number;
  companyId: number;
}

export interface UpdateProjectPayload {
  name: string;
  code: string;
  startDate: string;
  address: string;
  description: string;
  avatar: string;
  ownerName: string;
  ownerPhone: string;
  ownerEmail: string;
  status: number;
}

export interface ProjectMemberPagingResponse extends PagingResponse {
  results: ProjectMemberResponse[];
}

export interface ProjectMemberResponse {
  id: number;
  projectId: number;
  employeeId: number;
  employeeCode: number;
  name: string;
  code: string;
  role: number;
  roleName: string;
  status: number;
  phone: string;
  email: string;
  note: string;
  roleReadDTOs: {
    name: string;
    companyId: number;
    type: number;
    description: string;
    status: number;
    id: number;
  }[];
  teamReadDTO: {
    id: number;
    companyId: number;
    projectId: number;
    name: string;
    note: string;
    code: string;
    status: number;
    leader_Id: number;
    referenceFaceCKId: string;
  }[];
}

export interface ProjectRolePagingResponse extends PagingResponse {
  results: ProjectRoleResponse[];
}
export interface ProjectRoleResponse {
  id: number;
  name: string;
  companyId: number;
  type: number;
  description: string;
  status: number;
}

export interface CreateProjectMemberPayload {
  employeeId: number;
  name: string;
  code: string;
  role: number;
  roleName: string;
  status: number;
  note: string;
  projectId: number;
  roles: number[];
}

export interface UpdateProjectMember {
  employeeId: number;
  name: string;
  code: string;
  role: number;
  roleName: string;
  status: number;
  note: string;
  projectId: number;
  projectRoleIds: number[];
}
export interface Createticket {
  scale_code: string;
  driver_name: string;
  creator_name: string;
  trigger_code: string;
  section_id:string;
  license_plate: string;
  license_plate_ai: string;
  rock_type: string;
  rock_type_ai: string;
  weight: number;
  weight_ai: number;
  note: string;
}

class ProjectController {
  public Get = {
    getProjects: (options?: RequestOptions) => {
      return HttpClient.get(`http://localhost:5000/projects`, options);
    },
    getProjectById: (id: string, options?: RequestOptions) => {
      return HttpClient.get(`http://localhost:5000/projects/${id}`, options);
    },
    getProjectsByCompanyId: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Project/company/${companyId}`, options);
    },
    getEmployeesByCompanyId: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Employee/company/${companyId}`, options);
    },
    getRolesByCompanyId: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/ProjectRole/roleofCompany/${companyId}`, options);
    },
    getProjectStatusList: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Tag`, options);
    },
    getProjectMembers: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Project_Employee/${projectId}/member`, options);
    },
    getProjectRoles: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/ProjectRole`, options);
    },
  };
  public Post = {
    createProject: (inputValues: CreateProjectData, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Project`, inputValues, options);
    },
    createticket: (inputValues: Createticket, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/KafkaMsg/ticket`, inputValues, options);
    },
    createManyProjectMembers: (input: CreateProjectMemberPayload[], options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/Project_Employee/members`, input, options);
    },
  };
  public Put = {
    updateProject: (id: string, inputValues: UpdateProjectPayload, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Project/${id}`, inputValues, options);
    },
    updateProjectMember: (projectId : number, employeeId: number, input: UpdateProjectMember, options?: RequestOptions) => {
      const customOptions: RequestOptions = {
        ...options,
        search: { ...options?.search, employeeId },
      };
      return HttpClient.put(`${apiUrl}/Project_Employee/${projectId}`, input, customOptions);
    }
  };

  public Delete = {
    removeProjectMember: (projectId: number, employeeId: number, options?: RequestOptions) => {
      const customOptions: RequestOptions = {
        ...options,
        search: { ...options?.search, employeeId },
      };
      return HttpClient.delete(`${apiUrl}/Project_Employee/${projectId}`, customOptions);
    },
  };
}

export const ProjectService = new ProjectController();
