export const PrintHTMLToPDF = (data: any) => {
  var dataHtml = replaceHtml(data, htmlTemplate);
  var newWindows = window.open();
  console.log('print');
  if (newWindows) {
    newWindows.document.write(dataHtml);
    newWindows.document.close();
    newWindows?.print();
    newWindows?.focus();
    newWindows.onafterprint = () => {
      newWindows?.close();
    };
  }
};
const replaceHtml = (data: any, baseHtml: string) => {
  var htmlString = baseHtml;
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    var value = data[key];
    htmlString = htmlString.replace(new RegExp(`{{Model.${key}}}`, 'g'), value);
  }
  return htmlString;
};
var htmlTemplate = `
<!DOCTYPE html>
<html lang="vi">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Phiếu Đề Nghị Lấy Hàng</title>
    <style>
      .container {
        width: 500px;
        height: 250px;
        border: 5px solid black;
        font-family: "Times New Roman", Times, serif;
        margin-top: 150px;
        margin-bottom: 150px;
        margin-left: -50px;
        transform: rotate(-90deg);
      }

      .header {
        margin-right: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .title {
        font-size: 20px;
        font-weight: bold;
        margin: 7px auto;
      }

      .section {
        font-style: italic;
font-size:17px;        text-align: center;
        margin-top: 8px;
      }
      .section1 {
font-size:17px;        text-align: center;
        margin-top: 8px;
      }

      .flex-row {
        gap: 190px;
        display: flex;
        align-items: stretch;
      }

      .bold-text {
        font-weight: bold;
      }

      .left-aligns {
        text-align: left;
      }

      .congty,
      .nvien {
font-size:17px;        margin-bottom: 15px;
        margin-left: 15px;
      }
      .chungloai {
        font-weight: 600;
font-size:17px;        margin-bottom: 10px;
        margin-left: 15px;
      }
      .biensoxe {
        display: flex;
        align-items: center;
        justify-content: center;
font-size:17px;        margin-bottom: 15px;
        margin-left: 15px;
      }

      .line-break {
        border-bottom: 1px dashed black;
        margin: 150px 0;
        width: 700px;
      }
      .ngay {
        align-items: center;
      }
      .info-row {
        gap: 10px;
        display: flex;
        align-items: flex-start;
        width: 100%;
      }
      .nvien {
        display: flex;
        align-items: center;
        flex-grow: 1;
        line-height: 1.5;
      }

      .xuchang {
        display: flex;
        align-items: center;
        flex-grow: 1;
        line-height: 1.5;
      }

      .left-align {
        margin-left: 5px;
        text-align: left;
        white-space: nowrap;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <div class="title">PHIẾU ĐỀ NGHỊ LẤY HÀNG</div>
        <div>Số:....</div>
      </div>
      <div class="section">(Liên 1: lưu)</div>
      <div class="section1 flex-row">
        <div class="biensoxe">Biển số xe: {{Model.licensePlate}}</div>
        <div class="ngay">Ngày: {{Model.DateString}}</div>
      </div>
      <div class="congty">
        Công ty:
        {{Model.CompanyName}}........................................................................................
      </div>
      <div class="chungloai">Chủng loại đá: {{Model.rocktype}}</div>
      <div class="info-row">
        <div class="nvien">
          Nhân viên bán hàng:
          <span class="left-align">{{Model.creator_name}}</span>
        </div>
        <div class="xuchang">
          Xúc hàng:
          <span class="left-align">{{Model.creator_name}}</span>
        </div>
      </div>
    </div>

    <div class="line-break"></div>

    <div class="container">
      <div class="header">
        <div class="title">PHIẾU ĐỀ NGHỊ LẤY HÀNG</div>
        <div>Số:....</div>
      </div>
      <div class="section">(Liên 2: lưu)</div>
      <div class="section1 flex-row">
        <div class="biensoxe">Biển số xe: {{Model.licensePlate}}</div>
        <div class="ngay">Ngày: {{Model.DateString}}</div>
      </div>
      <div class="congty">
        Công ty:
        {{Model.CompanyName}}........................................................................................
      </div>
      <div class="chungloai">Chủng loại đá: {{Model.rocktype}}</div>
      <div class="info-row">
        <div class="nvien">
          Nhân viên bán hàng:
          <span class="left-align">{{Model.creator_name}}</span>
        </div>
        <div class="xuchang">
          Xúc hàng:
          <span class="left-align">{{Model.creator_name}}</span>
        </div>
      </div>
    </div>
  </body>
</html>

`;
