import React, { useState } from 'react';

import { InboxOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload, Form, Input, DatePicker, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './SettingProject.module.less';

const { TextArea } = Input;
const { Option } = Select;

export const SettingProject: React.FC = () => {
  const [capturedImages, setCapturedImages] = useState<{ [key: string]: string }>({
    avatar: '',
  });

  const { t } = useTranslation('settingproject');

  const handleRemoveImage = (key: string) => {
    setCapturedImages(prevImages => ({
      ...prevImages,
      [key]: '',
    }));
  };

  const renderPlaceholderContent = (key: string) => {
    return capturedImages[key] ? (
      <>
        <img src={capturedImages[key]} alt={key} className={styles.placeholderimage} />
        <CloseOutlined
          className={styles.closeicon}
          onClick={e => {
            e.stopPropagation();
            handleRemoveImage(key);
          }}
          aria-label="Remove image"
        />
      </>
    ) : (
      <div className={styles.placeholderupload}>
        <InboxOutlined className={styles.placeholdericon} />
        <p className={styles.placeholdertext}>{t('avatar')}</p>
      </div>
    );
  };

  const handleFileChange = ({ file }: any, key: string) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      setCapturedImages(prevImages => ({
        ...prevImages,
        [key]: e.target.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 style={{ fontSize: '24px', margin: 0, fontWeight: '500', color: 'rgba(0, 0, 0, 0.85)' }}>
          {t('settingproject')}
        </h2>
      </div>
      <div className={styles.table}>
        <h2>{t('projectinformation')}</h2>
        <Form layout="vertical">
          <Form.Item
            label={t('projectname')}
            name="projectName"
            rules={[{ required: true, message: 'Vui lòng nhập tên dự án!' }]}
          >
            <Input placeholder={t('projectname')} />
          </Form.Item>
          <div className={styles['form-row']}>
            <Form.Item
              label={t('startdate')}
              name="startDate"
              rules={[{ required: true, message: 'Vui lòng chọn ngày bắt đầu!' }]}
              className={styles['item-date']}
            >
              {' '}
              <DatePicker style={{ width: '100%' }} placeholder={t('startdate')} />
            </Form.Item>

            <Form.Item
              label={t('enddate')}
              name="endDate"
              rules={[{ required: true, message: 'Vui lòng chọn ngày kết thúc!' }]}
              className={styles['item-date']}
            >
              <DatePicker style={{ width: '100%' }} placeholder={t('enddate')} />
            </Form.Item>
          </div>
          <Form.Item label={t('address')} name="address">
            <Input placeholder={t('address')} />
          </Form.Item>
          <Form.Item label={t('note')} name="notes">
            <TextArea rows={4} placeholder={t('note')} />
          </Form.Item>
          <Form.Item label={t('status')} name="status">
            <Select placeholder={t('selectstatus')}>
              <Option value="new">Mới</Option>
              <Option value="inProgress">Đang thực hiện</Option>
              <Option value="completed">Hoàn thành</Option>
            </Select>
          </Form.Item>
        </Form>
        <div className={styles.placeholdersection}>
          {[t('Select or drag and drop to upload photos')].map(key => (
            <div
              key={key}
              className={`${styles.placeholderbutton} ${capturedImages[key] ? styles.filled : styles.empty}`}
              role="button"
              tabIndex={0}
            >
              <div className={styles.placeholder}>
                <Upload
                  showUploadList={false}
                  beforeUpload={file => {
                    handleFileChange({ file }, key);
                    return false;
                  }}
                >
                  {renderPlaceholderContent(key)}
                </Upload>
              </div>
              <p className={styles.placeholdertext}>{t(key)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingProject;
