import { createSlice } from '@reduxjs/toolkit';

import { ViewState, defaultPagingParams } from '@/common/define';
import { IssueTagListPagingResponse, IssuesPagingResponse, IssuesResponse } from '@/services/IssueService';

interface BiddingState {
  issues?: IssuesPagingResponse;
  selectedIssue?: IssuesResponse;
  issueStatus?: IssueTagListPagingResponse;
  issueProgress?: IssueTagListPagingResponse;
  view: ViewState;
  queryParams: any;
}

const initialState: BiddingState = {
  view: 'List',
  queryParams: defaultPagingParams
};

const issueSlice = createSlice({
  name: 'bidding',
  initialState,
  reducers: {
    setIssues: (state, action) => {
      state.issues = action.payload;
    },
    setSelectedIssue: (state, action) => {
      state.selectedIssue = action.payload;
    },
    setIssueStatuses: (state, action) => {
      state.issueStatus = action.payload;
    },
    setIssueProgress: (state, action) => {
      state.issueProgress = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getIssuesRequest: (state, action) => {},
    createIssueRequest: (state, action) => {},
    updateIssueRequest: (state, action) => {},
    removeIssueRequest: (state, action) => {},
    getStatusListRequest: (state, action) => {},
    getProgressListRequest: (state, action) => {},
  },
});

export const issueActions = issueSlice.actions;
export const issueReducer = issueSlice.reducer;
