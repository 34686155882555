import { combineEpics } from 'redux-observable';

import { appEpics } from './app';
import { ClientEpics } from './client';
import { employeeEpics } from './employee';
import { issueEpics } from './issue';
import { LuotXeEpics } from './luotxe/luotxeEpics';
import { porttwoEpics } from './porttwo/porttwoEpics';
import { projectEpics } from './project';
import { shiftEpics } from './shift';
import { teamEpics } from './team';
import { timekeepingEpics } from './timekeeping';
import { userEpics } from './user';

const rootEpics = combineEpics(
  ...appEpics,
  ...userEpics,
  ...timekeepingEpics,
  ...issueEpics,
  ...shiftEpics,
  ...projectEpics,
  ...employeeEpics,
  ...teamEpics,
  ...ClientEpics,
  ...porttwoEpics,
  ...LuotXeEpics,
);

export default rootEpics;
