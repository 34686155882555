import { createSelector } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { RootState } from '@/store/types';

export const getClientState = (state: RootState) => state.porttwo;

export const getClients = createSelector([getClientState], state => state.porttwoData);

export function getEmployeeQueryParams() {
  return createSelector([getClientState], state => state.queryParams || defaultPagingParams);
}
