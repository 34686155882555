import { createSlice } from '@reduxjs/toolkit';

import { ClientDatas } from '@/services/ClientService';

interface ClientState {
  clientData: ClientDatas[];
  newClient: ClientDatas | null;
}

const initialState: ClientState = {
  clientData: [],
  newClient: null
};

const ClientSlice = createSlice({
  name: 'Client',
  initialState,
  reducers: {
    getClients: (state, action) => {},
    setClients: (state, action) => {
      state.clientData = action.payload;
    },
    registerClient: (state, action) => {},
    registerSuccess: (state, action) => {
      state.newClient = action.payload;
    },
    updateClient: (state, action) => {},
  },
});

export const ClientActions = ClientSlice.actions;
export const ClientReducer = ClientSlice.reducer;
