import React from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Row, Col, DatePicker, Select, Upload } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './CreateProject.module.less'

interface CreateProjectProps {  
  isModalVisible: boolean;  
  handleOk: () => void;  
  handleCancel: () => void;  
}  
const { Option } = Select;

const CreateProject: React.FC<CreateProjectProps> = ({   
  isModalVisible,  
  handleOk,  
  handleCancel   
}) => {  
  const { t } = useTranslation('projectpage');  
  
  const uploadProps = {  
    beforeUpload: (file: any) => {  
      return false;  
    },  
    showUploadList: false,
  };  

  return (  
    <Modal   
      title={t('Title')}   
      visible={isModalVisible}   
      onOk={handleOk}   
      onCancel={handleCancel}  
      okText={t('Creatproject')}  
      okButtonProps={{ className: styles.customOkButton }}
    >  
      <Form layout="vertical">  
        <Form.Item 
          label={t('ProjectName')} 
          name="projectName" 
          rules={[{ required: true, message: t('ProjectName') }]}  
        >  
          <Input />  
        </Form.Item>  
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label={t('StartDate')} 
              name="startDate" 
              rules={[{ required: true, message: t('StartDate') }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={t('EndDate')} 
              name="endDate" 
              rules={[{ required: true, message: t('EndDate') }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item 
          label={t('Address')} 
          name="address" 
          rules={[{ required: true, message: t('Address') }]}
        >  
          <Input />  
        </Form.Item>  
        <Form.Item label={t('Note')} name="notes">  
          <Input.TextArea />  
        </Form.Item>  
        <Form.Item label={t('Status')} name="status">  
          <Select placeholder={t('StatusPlaceholder')} style={{ width: '100%' }}>
            <Option value="new">{t('New')}</Option>
            <Option value="inProgress">{t('Inprogress')}</Option>
            <Option value="completed">{t('Compledted')}</Option>
          </Select>
        </Form.Item>  
        <Form.Item label={t('Image')}>  
          <Upload {...uploadProps}>
            <div className={styles.uploadcontainer}>
              <InboxOutlined style={{ fontSize: '54px', color: '#4996e8' }}/> 
              <p>{t('Image')}</p>  
            </div>  
          </Upload>
        </Form.Item>  
      </Form>  
    </Modal>  
  );  
};  

export default CreateProject;
