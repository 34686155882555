import bidding from './bidding.json';
import checkin from './checkin.json';
import common from './common.json';
import employee from './employee.json';
import layout from './layout.json';
import login from './login.json';
import memberlist from './memberlist.json';
import organization from './organization.json';
import portone from './portone.json';
import projects from './projects.json';
import settingproject from './settingproject.json';
import shift from './shift.json';
import team from './team.json';
import welcome from './welcome.json';
// eslint-disable-next-line
export default {
  portone,
  bidding,
  common,
  welcome,
  login,
  layout,
  projects,
  checkin,
  shift,
  team,
  employee,
  settingproject,
  organization,
  memberlist,
};
