import { catchError, concat, filter, map, switchMap } from 'rxjs';

import { ClientActions } from './clientSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { ClientService } from '@/services/ClientService';
import { RequestOptions } from '@/services/types';
import Utils from '@/utils';


const getClientsRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(ClientActions.getClients.match),
    switchMap(action => {
      const options: RequestOptions = action.payload;
      return concat(
        [startLoading({ key: 'getClients' })],
        ClientService.Get.getAll(options).pipe(
          map(teams => ClientActions.setClients(teams)),
          catchError(error => {
            Utils.errorHandling(error);
            return [ClientActions.setClients([])];
          }),
        ),
        [stopLoading({ key: 'getClients' })],
      );
    }),
  );
};

const registerClient$: RootEpic = action$ => {
  return action$.pipe(
    filter(ClientActions.registerClient.match),
    switchMap(action => {
      const { data, files, option} = action.payload;
      const options: RequestOptions = option;
      console.log(data);
      return concat(
        [startLoading({ key: 'registerClient' })],
        ClientService.Post.create(data, files, options).pipe(
          map(teams => 
          {
            Utils.successNotification("Đã đăng ký thành công");
            return ClientActions.registerSuccess(teams)
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'registerClient' })],
      );
    }),
  );
};



export const ClientEpics = [
  getClientsRequest$,
  registerClient$,
];
