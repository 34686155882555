import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface TeamResponse {
  id: number;
  companyId: number;
  projectId: number;
  name: string;
  code: string;
  status: number;
  leader_Id: number;
}

export interface CreateTeamPayload {
  companyId: number;
  projectId: number;
  name: string;
  code: string;
  status: number;
  leader_Id: number;
}

export interface UpdateTeamPayload {
  name: string;
  code: string;
  status: number;
  leader_Id: number;
}

export interface CreateTeamMemberPayload {
  employeeId: number;
  name: string;
  code: string;
  role: number;
  roleName: string;
  status: number;
  note: string;
  teamId: number;
}

export interface RemoveTeamMemberPayload {
  employeeId: number;
  teamId: number;
}

class TeamController {
  public Get = {
    getTeams: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Team/project/${projectId}`, options);
    },
    getTeamDetails: (teamId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Team/${teamId}/details`, options);
    },
  };

  public Post = {
    createTeam: (input: CreateTeamPayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Team`, input, options);
    },
    createMember: (input: CreateTeamMemberPayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/Team_Employee`, input, options);
    },
    createManyTeamMembers: (input: CreateTeamMemberPayload[], options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/Team_Employee/many`, input, options);
    },
  };

  public Put = {
    updateTeam: (teamId: number, input: UpdateTeamPayload, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Team/${teamId}`, input, options);
    },
    updateTeamShift: (teamId: number, input: number[], options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/Team_Shift/team/${teamId}`, input, options);
    },
  };

  public delete = {
    removeTeam: (teamId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/api/Team/${teamId}`, options);
    },
    removeMember: (input: RemoveTeamMemberPayload, options?: RequestOptions) => {
      const customOptions: RequestOptions = { ...options, body: input };
      return HttpClient.delete(`${apiUrl}/Team_Employee/team/${input.teamId}/employee/${input.employeeId}`, customOptions);
    },
  };
}

export const TeamService = new TeamController();
