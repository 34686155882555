const apiUrl = `https://mo.hicas.vn`;

interface EnvVars {
  dev: EnvVar;
  prod: EnvVar;
}

interface EnvVar {
  identityUrl: string;
  apiUrl: string;
  checkInUrl: string;
  companyId: number;
  oAuthConfig: {
    issuer: string;
    clientId: string;
    scope: string;
    clientSecret?: string;
  };
  localization: {
    defaultResourceName: string;
  };
}

const ENV: EnvVars = {
  dev: {
    identityUrl: 'https://uat.pcvm.hicas.vn',
    apiUrl,
    checkInUrl: 'https://sit.checkin.tingconnect.com',
    companyId: 1,
    oAuthConfig: {
      issuer: apiUrl,
      clientId: 'PCVM',
      scope: 'offline_access API',
      clientSecret: 'ConstruxivViewersecret',
    },
    localization: {
      defaultResourceName: 'hicas',
    },
  },
  prod: {
    identityUrl: 'https://uat.pcvm.hicas.vn',
    apiUrl,
    checkInUrl: 'https://sit.checkin.tingconnect.com',
    companyId: 1,
    oAuthConfig: {
      issuer: apiUrl,
      clientId: 'PCVM',
      scope: 'offline_access API',
      clientSecret: 'ConstruxivViewersecret',
    },
    localization: {
      defaultResourceName: 'hicas',
    },
  },
};

export const getEnvVars = () => {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV === 'development' ? ENV.dev : ENV.prod;
};
