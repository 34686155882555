import { createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { EmployeeResponse, EmployeesPagingResponse } from '@/services/EmployeeService';

interface EmployeeState {
  employees?: EmployeesPagingResponse,
  selectedEmployee?: EmployeeResponse;
  selectedEmployeeDetails?: any;
  queryParams: any;
}

const initialState: EmployeeState = {
  queryParams: defaultPagingParams
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getEmployeesRequest: (state, action) => {},
    createEmployeeRequest: (state, action) => {},
    updateEmployeeRequest: (state, action) => {},
    removeEmployeeRequest: (state, action) => {},
  },
});

export const employeeActions = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;
