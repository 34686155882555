import { catchError, concat, filter, map, switchMap, EMPTY, of } from 'rxjs';

import { PortTwoActions } from './porttwoSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { PortTwoService } from '@/services/PortTwoService';
import Utils from '@/utils';

const getPorttwoRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(PortTwoActions.getClientPortTwo.match),
    switchMap(action => {
      const { projectId, options, workingDay, gateNo } = action.payload;
      console.log('action.payload', action.payload);

      return concat(
        of(startLoading({ key: 'getClientPortTwo' })),
        PortTwoService.Get.getAllDataPortTwo(projectId, gateNo, workingDay, options).pipe(
          map(response => {
            console.log('Response từ API:', response);
            return PortTwoActions.setClientPortTwo(response);
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return EMPTY;
          }),
        ),
        of(stopLoading({ key: 'getClientPortTwo' })),
      );
    }),
  );
};

export const porttwoEpics = [getPorttwoRequest$];
