import { catchError, concat, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';

import { issueActions } from './issueSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateIssueModalName,
  GettingIssueList,
  SavingIssue,
  GettingIssueProgressList,
  GettingIssueStatusList,
  defaultPagingParams,
  RemovingIssue,
} from '@/common/define';
import { IssueService } from '@/services/IssueService';
import Utils from '@/utils';

const getIssuesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.getIssuesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingIssueList })],
        IssueService.Get.getIssues(projectId, { search }).pipe(
          mergeMap(issues => {
            return [issueActions.setQueryParams(search), issueActions.setIssues(issues)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setIssues(undefined)];
          }),
        ),
        [stopLoading({ key: GettingIssueList })],
      );
    }),
  );
};

const createIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.createIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issue } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams };
      return concat(
        [startLoading({ key: SavingIssue })],
        IssueService.Post.createIssue(issue).pipe(
          switchMap(() => {
            return IssueService.Get.getIssues(issue.projectId, { search }).pipe(
              mergeMap(issues => {
                Utils.successNotification();
                return [
                  issueActions.setIssues(issues),
                  issueActions.setSelectedIssue(undefined),
                  hideModal({ key: CreateUpdateIssueModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [issueActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingIssue })],
      );
    }),
  );
};

const updateIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.updateIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueId, issue } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams };
      return concat(
        [startLoading({ key: SavingIssue })],
        IssueService.Put.updateIssue(issueId, issue).pipe(
          switchMap(() => {
            return IssueService.Get.getIssues(issue.projectId, { search }).pipe(
              mergeMap(issues => {
                Utils.successNotification();
                return [
                  issueActions.setIssues(issues),
                  issueActions.setSelectedIssue(undefined),
                  hideModal({ key: CreateUpdateIssueModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [issueActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingIssue })],
      );
    }),
  );
};

const removeIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.removeIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueId, projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingIssue })],
        IssueService.Delete.removeIssue(issueId).pipe(
          switchMap(() => {
            return IssueService.Get.getIssues(projectId, { search }).pipe(
              mergeMap(issues => {
                Utils.successNotification('Removed successfully');
                return [
                  issueActions.setIssues(issues),
                  issueActions.setSelectedIssue(undefined),
                  issueActions.setQueryParams(search),
                  hideModal({ key: CreateUpdateIssueModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [issueActions.setIssues(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingIssue })],
      );
    }),
  );
};


const getIssueStatusListRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.getStatusListRequest.match),
    switchMap(action => {
      const { projectId, params } = action.payload;
      return concat(
        [startLoading({ key: GettingIssueStatusList })],
        IssueService.Get.getIssueStatusList(projectId, { search: params }).pipe(
          map(statuses => issueActions.setIssueStatuses(statuses)),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [issueActions.setIssueStatuses(undefined)];
          }),
        ),
        [stopLoading({ key: GettingIssueStatusList })],
      );
    }),
  );
};

const getIssueProgressListRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.getProgressListRequest.match),
    switchMap(action => {
      const { projectId, params } = action.payload;
      return concat(
        [startLoading({ key: GettingIssueProgressList })],
        IssueService.Get.getIssueProgressList(projectId, { search: params }).pipe(
          map(statuses => issueActions.setIssueProgress(statuses)),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [issueActions.setIssueProgress(undefined)];
          }),
        ),
        [stopLoading({ key: GettingIssueProgressList })],
      );
    }),
  );
};

export const issueEpics = [
  getIssuesRequest$,
  createIssueRequest$,
  updateIssueRequest$,
  removeIssueRequest$,
  getIssueStatusListRequest$,
  getIssueProgressListRequest$,
];
