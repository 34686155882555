import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';

const getState = (state: RootState) => state.luotxe;

export function getLuotXesView() {
  return createSelector([getState], state => state.LuotXeData);
}
export function getLuotXeDay() {
  return createSelector([getState], state => state.luotXeTheoNgayData);
}
export function getLuotXeByDate() {
  return createSelector([getState], state => state.luotXeByDate);
}
export function getLuotXeMaHang() {
  return createSelector([getState], state => state.MaHangData);
}
export function getTongHopLuotXe() {
  return createSelector([getState], state => state.LuotXeData);
}
