import React, { useEffect, useState } from 'react';

import { EnvironmentOutlined, EllipsisOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Col, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CreateProject from './components/CreateProject';
import styles from './Projectpage.module.less';
import abcImage from '../../image/Container.png';
import { ProjectResponse } from '@/common/project';
import { getAuthenticated } from '@/store/app';
import { useAppSelector } from '@/store/hooks';
import { projectActions } from '@/store/project';
import { RootState } from '@/store/types';

export const Projectpage: React.FC = () => {
  const { t } = useTranslation('projects');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector(getAuthenticated());
  console.log('tkmk là', auth);

  const projects = useSelector((state: RootState) => state.project.projects);
  console.log('project là', projects);

  const filteredProjects =
    auth?.user?.Firstname === 'moda'
      ? projects.filter(project => project.id === 1)
      : auth?.user?.Firstname === 'modat'
      ? projects.filter(project => project.id === 2)
      : projects;

  useEffect(() => {
    dispatch(projectActions.getProjectsRequest(projects));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectProject = (item: ProjectResponse) => {
    dispatch(projectActions.setSelectedProject(item));
    navigate(`/project/dashboard`);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 style={{ fontSize: '26px', margin: 0, marginLeft: 20 }}>{t('Area')}</h3>
      </div>

      <div className={styles.ProjectItem}>
        <Row gutter={[16, 16]}>
          {filteredProjects.map(project => (
            <Col key={project.id} xs={24} sm={24} md={12} lg={8} xl={6}>
              <Card
                className={styles.cardWrapper}
                cover={<img alt="project" src={abcImage} style={{ height: '200px', objectFit: 'fill' }} />}
              >
                <h3>{project.name}</h3>
                <p>
                  <EnvironmentOutlined /> {project.location}
                </p>

                <div style={{ textAlign: 'right' }}>
                  <Button type="link" onClick={() => handleSelectProject(project)}>
                    {t('View')}
                    <RightOutlined style={{ color: '#888' }} />
                  </Button>
                </div>
                <Button className={styles.viewDetailsButton} type="link" icon={<EllipsisOutlined />} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <CreateProject isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />
    </div>
  );
};

export default Projectpage;
