import { Outlet } from 'react-router-dom';

import { AuthRouteObject } from './AuthRoute';
import MainLayout from '@/components/Layout/MainLayout';
import ProjectLayout from '@/components/Layout/ProjectLayout';
import { LoginPage } from '@/pages';
import NotFound from '@/pages/404';
import Dashboard from '@/pages/Dashboard';
import { HomePage } from '@/pages/HomePage';
import Organization from '@/pages/Organization';
import Porttwo from '@/pages/Porttwo';
import PorttwoDay from '@/pages/PorttwoDay';
import Projectpage from '@/pages/Projectpage';
import SettingProject from '@/pages/SettingProject';
import ZoneTracking from '@/pages/ZoneTracking';

type MetaMenu = {
  name?: string;
  icon?: React.ReactNode;
};

export type MetaMenuAuthRouteObject = AuthRouteObject<MetaMenu>;

export const projectRouters: MetaMenuAuthRouteObject[] = [
  {
    element: <Projectpage />,
    name: 'Project List',
    path: '/project',
    index: true,
  },
  {
    element: <ProjectLayout />,
    children: [
      {
        element: <Porttwo gateNo={1} />,
        name: 'Danh sach luot xe cong 1',
        path: '/project/vehicle/portone',
      },
      {
        element: <Porttwo gateNo={2} />,
        name: 'Danh sach luot xe cong 2',
        path: '/project/vehicle/porttwo',
      },
      {
        element: <PorttwoDay gateNo={1} />,
        name: 'Danh sach luot xe cong 1',
        path: '/project/vehicle/portoneday',
      },
      {
        element: <PorttwoDay gateNo={2} />,
        name: 'Danh sach luot xe cong 2',
        path: '/project/vehicle/porttwoday',
      },
      {
        element: <Dashboard />,
        name: 'Do thi tong hop',
        path: '/project/dashboard',
      },

      {
        element: <ZoneTracking />,
        name: 'Phieu can',
        path: '/project/WeightList',
      },
      {
        element: <SettingProject />,
        path: '/project/project-settings',
      },
    ],
  },
];
export const routers: MetaMenuAuthRouteObject[] = [
  {
    element: <MainLayout />,
    path: '/',
    name: 'Main',
    children: [
      {
        element: <HomePage />,
        name: 'Home',
        index: true,
        path: '/',
      },
      {
        element: <Organization />,
        name: 'Organization',
        path: '/organization',
      },
      {
        element: <Outlet />,
        name: 'Projects',
        path: '/project',
        children: projectRouters,
      },
    ],
  },
  {
    element: <LoginPage />,
    name: 'login',
    path: '/login',
  },
  { path: '*', element: <NotFound /> },
];
