import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface ClientRequest {
  Name: string;
  Email?: string;
  Role: string;
  Phone?: string;
}
export interface ClientDatas {
  name: string;
  email: string;
  phone: string;
  role: string;
  id: number;
  status: number;
  registerStatus: number;
  registerError: string;
  images: number[];
}
class ClientController {
  public Get = {
    getAll: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Client`, options);
    },
  };

  public Post = {
    create: (inputValues: ClientRequest, files: File[], options?: RequestOptions) => {
      return HttpClient.upload(`${apiUrl}/Client`, files, inputValues, options);
    },
  };
}

export const ClientService = new ClientController();
