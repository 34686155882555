import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { DataPortTwo } from '@/services/PortTwoService';
import { RequestOptions } from '@/services/types';

interface PortTwoState {
  porttwoData: DataPortTwo[];
  newClient: DataPortTwo | null;
  selectedPortTwo: DataPortTwo | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  } | null;
  queryParams: any;
}

const initialState: PortTwoState = {
  porttwoData: [],
  newClient: null,
  selectedPortTwo: null,
  loading: false,
  error: null,
  pagination: null,
  queryParams: defaultPagingParams,
};

const PortTwoSlice = createSlice({
  name: 'PortTwo',
  initialState,
  reducers: {
    getClientPortTwo: (
      state,
      action: PayloadAction<{ projectId: number; gateNo: number; workingDay: string; options: RequestOptions }>,
    ) => {},
    setClientPortTwo: (state, action: PayloadAction<DataPortTwo[]>) => {
      console.log('Dữ liệu phản hồi:', action.payload);
      state.porttwoData = action.payload;
    },

    updateClientPortTwo: (state, action) => {},
    updateClientSuccess: (state, action) => {
      state.selectedPortTwo = action.payload;
      state.loading = false;
    },
    getPortTwoById: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    registerClient: (state, action) => {},
  },
});

export const PortTwoActions = PortTwoSlice.actions;
export const PortTwoReducer = PortTwoSlice.reducer;
