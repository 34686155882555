import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from './app';
import { ClientReducer } from './client/clientSlice';
import { employeeReducer } from './employee/employeeSlice';
import { issueReducer } from './issue/issueSlice';
import { loadingReducer } from './loading';
import { LuotXeReducer } from './luotxe/luotxeSlice';
import { modalReducer } from './modal';
import { organizationReducer } from './organization';
import { PortTwoReducer } from './porttwo/porttwoSlice';
import { projectReducer } from './project';
import { shiftReducer } from './shift';
import { teamReducer } from './team';
import { timekeepingReducer } from './timekeeping';
import { userReducer } from './user';

const mainReducer = combineReducers({
  app: appReducer,
  loading: loadingReducer,
  modal: modalReducer,
  project: projectReducer,
  user: userReducer,
  timekeeping: timekeepingReducer,
  shift: shiftReducer,
  issue: issueReducer,
  team: teamReducer,
  employee: employeeReducer,
  organization: organizationReducer,
  client: ClientReducer,
  porttwo: PortTwoReducer,
  luotxe: LuotXeReducer,
});

const rootReducers = (state: any, action: any) => {
  // reset store if logout
  if (action.type === 'app/logout') {
    state = {
      app: {
        language: state.app.language,
      },
    };
  }

  return mainReducer(state, action);
};

export default rootReducers;
